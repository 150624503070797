<h1 mat-dialog-title>{{ id !== undefined ? 'Editar' : 'Crear' }} nota</h1>
<div mat-dialog-content>
  @if (origin === 'budget' || origin === 'customer' || origin === 'invoicing') {
    <mat-tab-group (selectedIndexChange)="changeTab($event)">
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>task</mat-icon>
          Tarea
        </ng-template>
        <ng-container
          *ngTemplateOutlet="formContainer"
          class="tab-content"></ng-container>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>note</mat-icon>
          Nota
        </ng-template>
        <ng-container
          *ngTemplateOutlet="formContainer"
          class="tab-content"></ng-container>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>notifications</mat-icon>
          Aviso
        </ng-template>
        <ng-container
          *ngTemplateOutlet="formContainer"
          class="tab-content"></ng-container>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>notifications</mat-icon>
          Aviso importante
        </ng-template>
        <ng-container
          *ngTemplateOutlet="formContainer"
          class="tab-content"></ng-container>
      </mat-tab>
    </mat-tab-group>
  } @else {
    <form [formGroup]="form" class="content">
      @if (noteType === 'message') {
        <mat-form-field>
          <mat-label>Departamentos</mat-label>
          <mat-select formControlName="departments" [multiple]="true">
            <mat-option>
              <ngx-mat-select-search
                [formControl]="departmentsFilterControl"
                placeholderLabel="Buscar departamento..."
                noEntriesFoundLabel="No se ha encontrado ningún departamento"></ngx-mat-select-search>
            </mat-option>
            @for (
              option of filteredDepartments | async | keyvalue;
              track option
            ) {
              <mat-option [value]="option.key | stringToNumber">{{
                option.value
              }}</mat-option>
            }
          </mat-select>
          @if (form.controls.departments.hasError('serverError')) {
            <mat-error>
              {{ form.controls.departments.getError('serverError') }}
            </mat-error>
          }
        </mat-form-field>
        <mat-form-field>
          <mat-label>Agente responsable</mat-label>
          <mat-select formControlName="responsibleId">
            <mat-option>
              <ngx-mat-select-search
                [formControl]="responsibleAgentFilterControl"
                placeholderLabel="Buscar usuario..."
                noEntriesFoundLabel="No se ha encontrado ningún usuario"></ngx-mat-select-search>
            </mat-option>
            @for (
              option of filteredResponsibleAgents | async | keyvalue;
              track option
            ) {
              <mat-option [value]="option.key | stringToNumber">{{
                option.value
              }}</mat-option>
            }
          </mat-select>
          @if (form.controls.responsibleId.hasError('serverError')) {
            <mat-error>
              {{ form.controls.responsibleId.getError('serverError') }}
            </mat-error>
          }
        </mat-form-field>
        <mat-slide-toggle class="w-100" formControlName="isImportant"
          >¿Importante?</mat-slide-toggle
        >
      }
      <mat-form-field class="w-100">
        <mat-label>Nota</mat-label>
        <textarea matInput formControlName="note"></textarea>
        @if (form.controls.note.hasError('serverError')) {
          <mat-error>
            {{ form.controls.note.getError('serverError') }}
          </mat-error>
        }
      </mat-form-field>
      @if (noteType === 'message') {
        <mat-form-field>
          <mat-label>Avisar a</mat-label>
          <mat-select formControlName="actionUserId">
            <mat-option>
              <ngx-mat-select-search
                [formControl]="actionUserFilterControl"
                placeholderLabel="Buscar usuario..."
                noEntriesFoundLabel="No se ha encontrado ningún usuario"></ngx-mat-select-search>
            </mat-option>
            @for (
              option of filteredActionUsers | async | keyvalue;
              track option
            ) {
              <mat-option [value]="option.key | stringToNumber">{{
                option.value
              }}</mat-option>
            }
          </mat-select>
          @if (form.controls.actionUserId.hasError('serverError')) {
            <mat-error>
              {{ form.controls.actionUserId.getError('serverError') }}
            </mat-error>
          }
        </mat-form-field>
      }
      @if (noteType === 'message' || noteType === 'task') {
        <mat-form-field>
          <mat-label>Cuándo</mat-label>
          <input
            type="datetime-local"
            formControlName="dateAction"
            matInput
            [min]="today" />
          <mat-hint>
            <span class="hour-hint" (click)="setDefaultDate('now')"
              >Ahora mismo</span
            >,
            <span class="hour-hint" (click)="setDefaultDate('tomorrow')"
              >Mañana por la mañana</span
            >
          </mat-hint>
          @if (form.controls.dateAction.hasError('serverError')) {
            <mat-error>
              {{ form.controls.dateAction.getError('serverError') }}
            </mat-error>
          }
        </mat-form-field>
      }
      @if (canAnswer) {
        <mat-divider></mat-divider>
        <mat-form-field>
          <mat-label>Escribir respuesta (opcional)</mat-label>
          <textarea matInput formControlName="answer"></textarea>
          @if (form.controls.answer.hasError('serverError')) {
            <mat-error>
              {{ form.controls.answer.getError('serverError') }}
            </mat-error>
          }
        </mat-form-field>
      }
    </form>
  }
  <ng-template #formContainer>
    <form [formGroup]="form" class="content">
      @if (noteType === 'message' || noteType === 'warning') {
        <mat-form-field>
          <mat-label>Departamentos</mat-label>
          <mat-select formControlName="departments" [multiple]="true">
            <mat-option>
              <ngx-mat-select-search
                [formControl]="departmentsFilterControl"
                placeholderLabel="Buscar departamento..."
                noEntriesFoundLabel="No se ha encontrado ningún departamento"></ngx-mat-select-search>
            </mat-option>
            @for (
              option of filteredDepartments | async | keyvalue;
              track option
            ) {
              <mat-option [value]="option.key | stringToNumber">{{
                option.value
              }}</mat-option>
            }
          </mat-select>
          @if (form.controls.departments.hasError('serverError')) {
            <mat-error>
              {{ form.controls.departments.getError('serverError') }}
            </mat-error>
          }
        </mat-form-field>
      }
      @if (noteType === 'message') {
        <mat-form-field>
          <mat-label>Agente responsable</mat-label>
          <mat-select formControlName="responsibleId">
            <mat-option>
              <ngx-mat-select-search
                [formControl]="responsibleAgentFilterControl"
                placeholderLabel="Buscar usuario..."
                noEntriesFoundLabel="No se ha encontrado ningún usuario"></ngx-mat-select-search>
            </mat-option>
            @for (
              option of filteredResponsibleAgents | async | keyvalue;
              track option
            ) {
              <mat-option [value]="option.key | stringToNumber">{{
                option.value
              }}</mat-option>
            }
          </mat-select>
          @if (form.controls.responsibleId.hasError('serverError')) {
            <mat-error>
              {{ form.controls.responsibleId.getError('serverError') }}
            </mat-error>
          }
        </mat-form-field>
      }
      <mat-form-field class="w-100">
        <mat-label>Nota</mat-label>
        <textarea matInput formControlName="note"></textarea>
        @if (form.controls.note.hasError('serverError')) {
          <mat-error>
            {{ form.controls.note.getError('serverError') }}
          </mat-error>
        }
      </mat-form-field>
      @if (noteType === 'message') {
        <mat-form-field>
          <mat-label>Avisar a</mat-label>
          <mat-select formControlName="actionUserId">
            <mat-option>
              <ngx-mat-select-search
                [formControl]="actionUserFilterControl"
                placeholderLabel="Buscar usuario..."
                noEntriesFoundLabel="No se ha encontrado ningún usuario"></ngx-mat-select-search>
            </mat-option>
            @for (
              option of filteredActionUsers | async | keyvalue;
              track option
            ) {
              <mat-option [value]="option.key | stringToNumber">{{
                option.value
              }}</mat-option>
            }
          </mat-select>
          @if (form.controls.actionUserId.hasError('serverError')) {
            <mat-error>
              {{ form.controls.actionUserId.getError('serverError') }}
            </mat-error>
          }
        </mat-form-field>
      }
      @if (noteType === 'message' || noteType === 'task') {
        <mat-form-field>
          <mat-label>Cuándo</mat-label>
          <input
            type="datetime-local"
            formControlName="dateAction"
            matInput
            [min]="today" />
          <mat-hint>
            <span class="hour-hint" (click)="setDefaultDate('now')"
              >Ahora mismo</span
            >,
            <span class="hour-hint" (click)="setDefaultDate('tomorrow')"
              >Mañana por la mañana</span
            >
          </mat-hint>
          @if (form.controls.dateAction.hasError('serverError')) {
            <mat-error>
              {{ form.controls.dateAction.getError('serverError') }}
            </mat-error>
          }
        </mat-form-field>
      }
      @if (canAnswer) {
        <mat-divider></mat-divider>
        <mat-form-field>
          <mat-label>Escribir respuesta (opcional)</mat-label>
          <textarea matInput formControlName="answer"></textarea>
          @if (form.controls.answer.hasError('serverError')) {
            <mat-error>
              {{ form.controls.answer.getError('serverError') }}
            </mat-error>
          }
        </mat-form-field>
      }
    </form>
  </ng-template>
</div>
<div class="dialog-actions" mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false">Cancelar</button>
  <button mat-raised-button (click)="save()"> Guardar </button>
</div>
